import { type FC, type PropsWithChildren } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import PublicFooter from 'components/molecules/PublicFooter';
import PublicHeader from 'components/molecules/PublicHeader';

const PublicLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <PublicHeader />
      <main id="home-main">{children}</main>
      <PublicFooter />
      <ScrollRestoration />
    </>
  );
};

export default PublicLayout;
